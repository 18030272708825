import { useEffect } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  useEffect(() => {
    // redirect to home page
    window.location.href = '/';
  }, []);
  return null;
};
